import { Directive, EmbeddedViewRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { PermissionOption } from '@reducers/orm/permission/permission.model';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import {
  getPermissionState,
  hasAllPermissions,
  hasAllPermissionsForReports,
  hasPermission,
  PermissionCheck,
} from '../../reducers/auth/permission.helper';
import { AppState } from '../../reducers/index';

enum Check {
  ANY = 'any',
  ALL = 'all',
  REPORTS = 'reports',
}

@Directive({
  selector: '[permission]',
  standalone: true,
})
export class PermissionDirective implements OnInit, OnDestroy {
  private permission$: BehaviorSubject<PermissionOption> = new BehaviorSubject(null);
  private permissionGroups$: BehaviorSubject<PermissionOption[]> = new BehaviorSubject(null);
  private department$: BehaviorSubject<string | string[]> = new BehaviorSubject(null);
  private user$: BehaviorSubject<string> = new BehaviorSubject(null);
  private check$: BehaviorSubject<Check> = new BehaviorSubject(Check.ANY);

  private dataSubscriptions = new Subscription();

  private _context: PermissionContext = new PermissionContext();
  private _thenTemplateRef: TemplateRef<PermissionContext> = null;
  private _elseTemplateRef: TemplateRef<PermissionContext> = null;
  private _thenViewRef: EmbeddedViewRef<PermissionContext> = null;
  private _elseViewRef: EmbeddedViewRef<PermissionContext> = null;

  @Input()
  public set permission(permission: PermissionOption) {
    this.permission$.next(permission);
  }

  @Input()
  public set permissionDepartment(department: string | string[]) {
    this.department$.next(department);
  }

  @Input()
  public set permissionUser(userId: string) {
    this.user$.next(userId);
  }

  // Checks if it complies with every group
  @Input()
  public set permissionGroups(groups: PermissionOption[]) {
    this.permissionGroups$.next(groups);
  }

  @Input()
  public set permissionCheck(check: Check) {
    this.check$.next(check);
  }

  @Input()
  public set permissionThen(templateRef: TemplateRef<PermissionContext>) {
    this._thenTemplateRef = templateRef;
    this._thenViewRef = null; // clear previous view if any.
    this._updateView();
  }

  @Input()
  public set permissionElse(templateRef: TemplateRef<PermissionContext>) {
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null; // clear previous view if any.
    this._updateView();
  }

  public constructor(
    private _viewContainer: ViewContainerRef,
    private store: Store<AppState>,
    templateRef: TemplateRef<PermissionContext>,
  ) {
    this._thenTemplateRef = templateRef;
  }

  public ngOnInit() {
    //when guard or permissionState changes, check permissions
    this.dataSubscriptions.add(
      combineLatest([
        this.permission$.pipe(distinctUntilChanged()),
        this.permissionGroups$.pipe(distinctUntilChanged()),
        this.department$.pipe(distinctUntilChanged()),
        this.user$.pipe(distinctUntilChanged()),
        this.check$.pipe(distinctUntilChanged()),
        this.store.select(getPermissionState),
      ])
        .pipe(
          map(([permissions, permissionGroups, departments, userId, checkType, permissionState]) => {
            if (permissionGroups && permissionGroups.length) {
              return permissionGroups.every((permission) =>
                this.checkPermission(permission, userId, departments, checkType, permissionState),
              );
            } else {
              return this.checkPermission(permissions, userId, departments, checkType, permissionState);
            }
          }),
          distinctUntilChanged(),
        )
        .subscribe((show) => {
          this._context.$implicit = show;
          this._updateView();
        }),
    );
  }

  private _updateView() {
    if (this._context.$implicit) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        this._elseViewRef = null;
        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
        }
      }
    } else {
      if (!this._elseViewRef) {
        this._viewContainer.clear();
        this._thenViewRef = null;
        if (this._elseTemplateRef) {
          this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef, this._context);
        }
      }
    }
  }

  private checkPermission(
    permissions: PermissionOption,
    userId: string,
    departments: string | string[],
    checkType: Check,
    permissionState,
  ) {
    const permissionCheck: PermissionCheck = {
      permissions,
      userId,
      departments,
    };

    if (checkType === 'reports') {
      return hasAllPermissionsForReports(permissionCheck, permissionState);
    } else if (checkType === 'all') {
      return hasAllPermissions(permissionCheck, permissionState);
    }

    return hasPermission(permissionCheck, permissionState);
  }

  public ngOnDestroy() {
    this.dataSubscriptions.unsubscribe();
  }
}

export class PermissionContext {
  public $implicit = null;
}
